import React from 'react';
import theo from '../images/theo.jpg'

const TextBox3 = () => {
  return (
    <div className='text-box1'>
        
        <img src={theo} className='theo' />
    </div>
  )
}

export default TextBox3;