import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
        <p>For more information: <a href="doug.rosenberg@gmail.com">doug.rosenberg@gmail.com</a></p>
    </div>
  )
}

export default Footer